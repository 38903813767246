<template>
  <div>
      <div class="seachbar">
        <div class="ctn">
            <img src="../assets/images/search.png" class="big">
            <form action="javascript:return false">
            <input @keyup.enter="searchAction($event)" @blur="searchAction($event)" type="search" class="search" ref="over"><!-- @keyup.enter="search"-->
            </form>
        </div>
        <img src="../assets/images/logo.png" class="logo">
      </div>

      <swiper class="swiper" :options="swiperOptions" v-if="ad.length>0">
        <swiper-slide v-for="(items, index) in ad" :key="index._id">
          <a :href="items.url"><img :src="items.image"></a>
        </swiper-slide>
        <div class="swiper-pagination pagination-home"  slot="pagination"></div>
      </swiper>
    <!-- <transition-group name="staggered-fade" tag="div" v-bind:css="false"> -->
    <div v-if="searchResult.length > 0">
      <div v-for="(item, index) in searchResult" :key="item._id"  :data-index="index"
      @click.stop="searchplayer(index, item._id)"
      :class="{ light: isLight == item._id }">
        <div class="info">
          <div class="photo">
            <img v-lazy="item.icon" height="170px" class="icon">
            <img src="../assets/images/2555.png" height="160px" class="heijiao">
          </div>
          <div class="title">
            <h2>{{item.title}}</h2>
            <span>• • •</span>
          </div>
          <div class="name">
            <span>{{item.name}}</span>
          </div>
          <div class="date">
            <span>发行日 {{item.createdAt.slice(0, 10)}}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-for="(item, i) in music" :key="item._id" :data-index="index" @click.stop="player(i, item._id)" :class="{ light: isLight == item._id}">
        <div class="info">
          <div class="photo">
            <img v-lazy="item.icon" height="170px" class="icon">
            <img src="../assets/images/2555.png" height="160px" class="heijiao">
          </div>
          <div class="title">
            <h2>{{item.title}}</h2>
            <span>• • •</span>
          </div>
          <div class="name">
            <span>{{item.name}}</span>
          </div>
          <div class="date">
            <span>发行日 {{item.createdAt.slice(0, 10)}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- </transition-group> -->
      <div>
    </div>
    <div class="bot">
    </div>
    <div class="player">
      <div class="play_icon">
        <img v-lazy="playIcon1" class="icon">
      </div>
      <!-- <div class="song">
        {{playerName}}
      </div> -->
      <div class="bar">
        <div class="progressbar" @click="playMusic" ref="runfatbar">
          <div class="movebar" ref="runbar">
            <span class="dot" draggable="true"></span>
          </div>
        </div>
        <div class="controls">
          <i class="btn_prev" @click="prevMusic"><img src="../assets/images/prev.png" height="90px"></i>
          <i class="btn_next" @click="nextMusic"><img src="../assets/images/next.png" height="90px"></i>
          <i class="auto_next" @click="autoMusic" v-if="isNext"><img src="../assets/images/proper.png" height="90px"></i>
          <i class="auto_random" @click="autoRandom" v-else-if="isRandom"><img src="../assets/images/random.png" height="90px"></i>
          <i class="auto_loop" @click.stop="autoLoop" v-else><img src="../assets/images/loop.png" height="90px"></i>
          <div class="howTime">
            <span>{{cTime}}</span>
            <!-- /<span>{{dTime}}</span> -->
            <audio :src="playUrl"  @ended="onEnded" autoplay="autoplay" ref="audio"></audio>
          </div>
        </div>
        <div class="isplay">
          <img @click="ting" v-if="isPlay" src="../assets/images/he1.png" class="pausestyle">
          <img  @click="pao" v-else src="../assets/images/he2.png" class="pausestyle">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'swiper-example-fade-effect',
  title: 'Fade effect',
  data () {
    return {
      searchResult: [],
      cTime: '00:00',
      dTime: '00:00',
      ad: [],
      swiperOptions: {
        spaceBetween: 30,
        effect: 'fade',
        loop: true,
        observer:true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      music:[],
      playUrl: [],
      playIcon: {},
      playIcon1: require('../assets/images/6665.png'),
      index: 0,
      isNext: true,
      isRandom: false,
      playerName: '暂无播放歌曲',
      isPlay: true,
      isLight: ''
      }
    },
    computed: {
      // computedList: function () {
      //   let vm = this
      //   return this.music.filter(function (item) {
      //     return item.name.toLowerCase().indexOf(vm.query.toLowerCase()) !== -1 || item.title.toLowerCase().indexOf(vm.query.toLowerCase()) !== -1
      //   })
      // }
    },
    methods: {
      // async search() {
      //   const res = await this.$http.get('musics/search')
      //   console.log(res);
      //   this.music = res.data
      // },
      async fetchMusic(){
        const res = await this.$http.get('musics/list')
        this.music = res.data.sort(()=> Math.random() - 0.5)
      // console.log(this.music);
      },
      async fetchAd(){
        const res = await this.$http.get('ads/list')
        this.ad = res.data.sort(()=> Math.random() - 0.5)
      },
      async player(i, value) {
        const res = await this.music   //$http.get('musics/list')
        const change = await this.$refs.audio
        if(change.play){
          this.isPlay = false
          this.$nextTick(() => this.$refs.audio.play())
        }
        this.index = i
        this.playUrl = res[this.index].audio;
        this.playIcon1 = res[this.index].icon;
        this.isLight = value
        this.playerName = res[this.index].name + '-' +res[this.index].title
      },
      // 上一首
      async prevMusic() {
        const res = await this.music   //$http.get('musics/list')
        const change = await this.$refs.audio
        if(change.play){
          this.isPlay = false
          this.$nextTick(() => this.$refs.audio.play())
        }
        // console.log(this.index);
            // console.log(this.index);
            let index = this.index - 1
            if(index < 0) {
                index = this.music.length - 1
            }
            this.index = index
            this.playUrl = res[this.index].audio;
        this.playIcon1 = res[this.index].icon;
        this.playerName = res[this.index].name + '-' +res[this.index].title
        this.isLight = res[this.index]._id
            this.$nextTick(() => this.$refs.audio.play())
        },
        // 下一首
        async nextMusic() {
          const res = await this.music   //$http.get('musics/list')
          const change = await this.$refs.audio
          if(change.play){
            this.isPlay = false
            this.$nextTick(() => this.$refs.audio.play())
          }
          if(this.isRandom == true) {
            let index = Math.floor(Math.random()*this.music.length) //.random().this.index
              // console.log(index);
            this.index = index
          }else {
            let index = this.index + 1
            if(index === this.music.length) {
                index = 0
            }
            this.index = index
          }
          this.playUrl = res[this.index].audio
          this.playIcon1 = res[this.index].icon
          this.playerName = res[this.index].name + '-' +res[this.index].title
          this.isLight = !this.isLight
          this.isLight = res[this.index]._id
this.$nextTick(() => this.$refs.audio.play())
        },
        // 播放结束
    async onEnded() {
    //   this.audioImg = "./dialogDetailPlay.png";
      const res = await this.music
      const change = await this.$refs.audio
      if(this.isNext == true){
        let index = this.index + 1
        if(index === this.music.length) {
          index = 0
        }
        this.index = index;
        this.playUrl = res[this.index].audio;
        this.playIcon1 = res[this.index].icon;
        this.isLight = res[this.index]._id
        this.playerName = res[this.index].name + '-' + res[this.index].title
        this.cTime = "00:00";
        this.$refs.runbar.style.width = 0;
      }else if(this.isRandom == true) {
        let index = Math.floor(Math.random()*this.music.length) //.random().this.index
        this.index = index;
        this.playUrl = res[this.index].audio;
        this.playIcon1 = res[this.index].icon;
        this.isLight = res[this.index]._id
        this.playerName = res[this.index].name + '-' + res[this.index].title
        this.cTime = "00:00";
        this.$refs.runbar.style.width = 0;
      }else{
        let index = this.index
        this.index = index
        this.playUrl = res[this.index].audio
        this.playIcon1 = res[this.index].icon
        this.isLight = res[this.index]._id
        this.playerName = res[this.index].name + '-' + res[this.index].title
        this.cTime = "00:00"
        this.$refs.runbar.style.width = 0
        if (this.searchResult.length > 0) {
          this.playUrl = this.searchResult[this.index].audio
          this.playIcon1 = this.searchResult[this.index].icon
          this.isLight = this.searchResult[this.index]._id
          this.playerName = this.searchResult[this.index].name + '-' + this.searchResult[this.index].title
        }
      }
      if(change.play){
        this.isPlay = false
        this.$nextTick(() => this.$refs.audio.play())
      }
			// this.$nextTick(() => this.$refs.audio.play())
    },
        // 顺序播放
        autoMusic() {
          // const res = await this.$http.get('musics/list')
          this.isNext = !this.isNext
          this.isRandom = !this.isRandom
        },
        autoLoop() {
          this.isNext = true
        },
        // 随机播放
        autoRandom() {
          this.isRandom = !this.isRandom
        },
    async ting() {
      const change = await this.$refs.audio
      if(change.play){
          this.isPlay = false
          change.play()
        }
    },
    async pao() {
      const change = await this.$refs.audio
      if(change.pause) {
        this.isPlay = true
        change.pause()
      }
    },
    searchAction(e) {
      const inputValue = e.target.value
      this.$refs.over.blur()
      this.searchResult = this.music.filter((item)=> {
        if (item.name.toUpperCase().includes(inputValue.toUpperCase()) || item.title.toUpperCase().includes(inputValue.toUpperCase())) {
          return item
        }
      })
    },
    searchplayer(index, value) {
      this.index = index
      this.playUrl = this.searchResult[this.index].audio
      this.isLight = value
      this.playIcon1 = this.searchResult[this.index].icon;
      this.isPlay = false
      this.$nextTick(() => this.$refs.audio.play())
    },
        
    playMusic(e) {
        const sing = this.$refs.audio
        const barWidth = e.layerX / this.$refs.runfatbar.offsetWidth
        this.$refs.runbar.style.width = `${barWidth * 100}%`
        sing.currentTime = sing.duration * barWidth
        // console.log(sing.currentTime);
        sing.play()
    },
  },
    
    updated() {
        const sing = this.$refs.audio
        const singBar = this.$refs.runbar
        const singWidth = this.$refs.runfatbar.offsetWidth

        sing.addEventListener('canplay', ()=> {
            // console.log(e);
            const singTime = sing.duration
            const branch = Math.floor(singTime / 60)
            const secend = Math.ceil(singTime % 60)
            if(branch < 10 && secend < 10) {
                this.dTime =`0${branch}:0${secend}`
            }else if (branch < 10) {
                this.dTime = `0${branch}:${secend}`
            }else if (secend < 10) {
                this.dTime = `${branch}:0${secend}`
            }else {
                this.dTime = `${branch}:${secend}`
            }
        }),
        
        sing.addEventListener('timeupdate', () => { //
            // setTimeout(() => {
              const singTime = sing.duration
            const stopTime =sing.currentTime
            singBar.style.width = `${(stopTime / singTime) * 100}%`
            const branch = Math.floor(stopTime / 60)
            const second = Math.ceil(stopTime % 60)
            if ( branch < 10 && second < 10) {
                this.cTime =`0${branch}:0${second}`
            }else if (branch < 10) {
                this.cTime = `0${branch}:${second}`
            }else if (second < 10) {
                this.cTime = `${branch}:0${second}`
            }else {
                this.cTime = `${branch}:${second}`
            }
            // },100);
        }),

        singBar.addEventListener('touchmove', (event) => {
            // console.log(event);
            const events = event.targetTouches[0].pageX
            singBar.style.width = `${(events / singWidth) * 100}%`
            sing.pause()
        })
        singBar.addEventListener('touchend', () => {
            const touWidth = (singBar.offsetWidth / singWidth)
            sing.currentTime = sing.duration * touWidth
            sing.play()
            // console.log(sing.currentTime);
        })
    },
    
    created () {
    this.fetchMusic(),
    this.fetchAd()
  }
}

</script>

<style lang="scss">
.seachbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 980px;
    height: 150px;
    background: #f3db3b;
    margin: 0 auto;
    z-index: 20;
    ul {
        width: 920px;
        height: 550px;
        margin: 150px auto 0;
        li {
            list-style: none;
            padding-top: 20px;
            div {
                height: 30px;
                span {
                    padding-right: 10px;
                }
            }
        }
    }
    .ctn {
        float: left;
        width: 780px;
        height: 80px;
        background: #000;
        border-radius: 50px;
        margin-top: 35px;
        margin-left: 30px;
        .big {
            float: left;
            width: 41px;
            height: 41px;
            margin-top: 20px;
            margin-left: 20px;
            margin-right: 10px;
        }
        .search {
            float: left;
            width: 663px;
            height: 80px;
            font-size: 45px;
            background: #000;
            color: #f3db3b;
            outline: none;
            border: none;
        }
    }
    .logo {
            float: right;
            width: 110px;
            height: 110px;
            margin-top: 20px;
            margin-right: 30px;
        }
}

.swiper {
  width: 920px !important;
  height: 480px !important;
  margin-top: 170px;
  border-radius: 20px;
}
.pagination-home {
  .swiper-pagination-bullet {
    background: #fff;
    &.swiper-pagination-bullet-active {
      background: #fff;
    }
  }
}
.light {
  background: #F3DB3B10;
}
.info {
  width: 920px;
  height: 170px;
  color: #fff;
  margin: 30px auto 0;
  .date {
    span {
      font-size: 28px;
      display: inline-block;
      margin: 12px 0 0 20px;
      color: #adadad;
    }
  }
  .name {
    span {
      width: 630px;
      height: 40px;
      font-size: 30px;
      margin: 10px 0 0 20px;
      display: inline-block;
      overflow: hidden;
    }
  }
.title {
  position: relative;
  height: 62px;
  h2 {
    width: 590px;
    height: 45px;
    font-size: 35px;
    font-weight: 400;
    display: inline-block;
    margin: 10px 0 0 20px;
    overflow: hidden;
  }
  span {
    position: absolute;
    top: 5px;
    right: 20px;
    font-size: 30px;
    color: #f3db3b;
  }
}
  .photo {
    float: left;
    position: relative;
    width: 235px;
    height: 170px;
    .heijiao {
      position: absolute;
      top: 6px;
      right: 0;
    }
    .icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 170px;
      height: 170px;
      border-radius: 10px;
      z-index: 5;
    }
  }
}

.bot {
  width: 980px;
  height: 240px;
}
.player {
  position: fixed;
  bottom: 35px;
  left: 0;
  right: 0;
  width: 920px;
  height: 170px;
  margin: 0 auto;
  background: #000;
  z-index: 20;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  .play_icon {
      float: left;
      position: relative;
      height: 170px;
      width: 170px;
      .icon {
        width: 170px;
        height: 170px;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }
  }
  .song {
      float: left;
      width: 660px;
      height: 3rem;
      margin: 30px 0 0 20px;
      font-size: 40px;
      overflow: hidden;
      color: #fff;
  }
  .isplay {
    .pausestyle {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 110px;
        height: 110px;
      }
  }
//   进度条
  .bar {
      float: left;
      width: 580px;
      height: 50px;
      margin: 20px 0 0 0;
      // 底部
      .controls {
        margin-top: 23px;
        i {
          padding-left: 50px;
        }
      }
      // 播放时间样式
      .howTime {
        float: right;
        font-size: 50px;
        color: #6d6965;
        line-height: 94px;
        font-weight: 700;
      }
      // 进度条样式
      .progressbar {
            width: 540px;
            height: 10px;
            position: relative;
            border-radius: 50px;
            background: #e6e6e6;
            margin-top: 20px;
            display: none;
        }
        .movebar {
            height: 100%;
            border-radius: 50px;
            position: absolute;
            background: #fff;
        }
        .dot {
            display: inline-block;
            padding: 10px;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            top: -5px;
            right: -5px;
            cursor: pointer;
        }
  }
}
</style>
