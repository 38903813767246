import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Main from '../views/Main.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'mian',
    component: Main
    // children: [
    //   {path: '/', name: 'home', component: Home}
    // ]
  }
  
]

const router = new VueRouter({
  routes
})

export default router
